html,
body,
blockquote {
  margin: 0;
}

html {
  background: #fffdf9;
  color: #2d2e2e;
}

body {
  font-family: "Open Sans", sans-serif;
}

tt,
kbd,
pre,
code,
samp {
  /* https://modernfontstacks.com/ */
  font-family: ui-monospace, "Cascadia Code", "Source Code Pro", Menlo,
    Consolas, "DejaVu Sans Mono", monospace;
}
